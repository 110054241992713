import "../../Styling/Portfolio/PortfolioDetails.css";
import { motion } from "framer-motion";
import { portfolioData } from "../../Data/ShowcaseData";
import { useParams } from "react-router-dom";
import NavBar from "../Home/NavBar";

const variant = {
  visible: { scale: 1 },
  hidden: { scale: 0 },
};

const PortfolioDetails = () => {
  const { id } = useParams();

  const getPortfolioItem = (id) => {
    return portfolioData.find((item) => item.id === id);
  };

  return (
    <div>
      <NavBar />
      <main className="details-wrapper">
        <div className="content">
          {/* Header */}
          <div className="header">
            {/* Logo */}
            <div className="logo-wrapper">
              <div className="logo-bg">
                <img
                  className="logo-image"
                  alt=""
                  src={getPortfolioItem(id).logo}
                />
              </div>
            </div>
            {/* Title */}
            <h1 className="title">{getPortfolioItem(id).title}</h1>
            {/* Stats */}
            <div className="stats-wrapper">
              {/* Platform */}
              <div className="stats-item-wrapper">
                <p className="stats-item-title">Platform</p>
                <h6 className="stats-item-value">
                  {getPortfolioItem(id).platform}
                </h6>
              </div>
              {/* Category */}
              <div className="stats-item-wrapper">
                <p className="stats-item-title">Category</p>
                <h6 className="stats-item-value">
                  {getPortfolioItem(id).categories}
                </h6>
              </div>
            </div>
            {/* Links */}
            <div className="links-wrapper">
              <span className="links-span">
                <button className="links-button">Download</button>
              </span>
            </div>
          </div>
          <section className="more-work-section-wrapper">
            {/* Grid */}
            <div className="portfolio-grid">
              {getPortfolioItem(id).images.map((item, idx) => (
                <motion.a
                  key={idx}
                  className="link"
                  href={`/portfolio/details/${item.id}`}
                  whileHover={{ scale: 1.025 }}
                >
                  <motion.div
                    className="image-wrapper"
                    variants={variant}
                    initial="hidden"
                    whileInView="visible"
                  >
                    <img className="image" alt={item} src={item} />
                  </motion.div>
                </motion.a>
              ))}
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default PortfolioDetails;
