import "../../Styling/Header.css";
import { trustedData } from "../../Data/ShowcaseData";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const trustedDataValues = [...Object.values(trustedData)];

  let navigate = useNavigate();

  return (
    <div>
      <div className="header-wrapper">
        <img
          className="image-logo"
          src="https://minimal.gallery/wp-content/uploads/2022/12/mobbin-2-630x630.png"
          alt=""
        />
        <h1 className="header-title">
          Discover real-world design inspiration.
        </h1>
        <h4 className="header-subtitle">
          Featuring over 300,000 screens and 1,000 iOS, Android & Web apps.
        </h4>
        <div className="header-button-wrapper">
          <button
            className="header-button-one"
            onClick={(e) => {
              e.preventDefault();
              navigate("contact");
            }}
          >
            Get a quote
          </button>
          {/* <button className="header-button-two" onClick={(e) => {
            e.preventDefault()
            navigate('portfolio')
          }}>
            <span
              style={{
                columnGap: "8px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <span
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                See our work
              </span>
              <IconArrowRight
                color="#fff"
                size={15}
                stroke={3}
                style={{
                  background: "#2b2b2b",
                  padding: "4px",
                  borderRadius: 20,
                }}
              />
            </span>
          </button> */}
        </div>
        {/* Trusted */}
        <div className="header-trusted-wrapper">
          <p className="header-trusted-wrapper-title">Trusted by companies</p>
          <div className="trusted-images-wrapper">
            {trustedDataValues.map((item, idx) => (
              <img
                key={idx}
                className="trusted-image"
                alt=""
                src={item.imgUrl}
                style={{ objectFit: "cover", width: "100%", height: "100%" }} // Make sure the image fills the container
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
