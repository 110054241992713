import "./Styling/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Showcase from "./Views/Home/Showcase";
import Header from "./Views/Home/Header";
import Workflow from "./Views/Home/Workflow";
import Quality from "./Views/Home/Quality";
import NavBar from "./Views/Home/NavBar";
import Footer from "./Views/Home/Footer";
import PortfolioList from "./Views/Portfolio/PortfolioList";
import PortfolioDetails from "./Views/Portfolio/PortfolioDetails";
import Contact from "./Views/Contact/Contact";
import useDocumentTitle from "./Utilities/useDocumentTitle"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<PortfolioList />} />
        <Route path="/portfolio/details/:id" element={<PortfolioDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

const Home = () => {

  useDocumentTitle("Title");
  return (
    <main className="main-wrapper">
      <div className="main-content">
        <NavBar />
        <Header />
        <Showcase />
        <Quality />
        <Workflow />
        <Footer />
      </div>
    </main>
  )
}

export default App;
