import "../../Styling/Portfolio/PortfolioList.css";
import { motion } from "framer-motion";
import { portfolioData } from "../../Data/ShowcaseData";
import NavBar from "../Home/NavBar";

const variant = {
  visible: { scale: 1 },
  hidden: { scale: 0 },
};

const PortfolioList = () => {
  return (
    <div>
      <NavBar />

      <main className="portfolio-main">
        <div className="portfolio-wrapper">
          <h1 className="portfolio-title">Portfolio</h1>
          {/* Selector */}
          <div className="portfolio-segment-control-wrapper">
            <span className="portfolio-segment-control-span">
              <a
                href="/portfolio"
                className="portfolio-segment-control-span-link"
              >
                Apps
              </a>
            </span>
            <span className="portfolio-segment-control-span">
              <a
                href="/portfolio"
                className="portfolio-segment-control-span-link"
                style={{ color: "#909090" }}
              >
                Websites
              </a>
            </span>
            <span className="portfolio-segment-control-span">
              <a
                href="/portfolio"
                className="portfolio-segment-control-span-link"
                style={{ color: "#909090" }}
              >
                Automation
              </a>
            </span>
          </div>
          {/* Grid */}
          <section className="more-work-section-wrapper">
            {/* Grid */}
            <div className="portfolio-grid">
              {Object.values(portfolioData).map((item, idx) => (
                <motion.a
                  key={idx}
                  className="link"
                  href={`/portfolio/details/${item.id}`}
                  whileHover={{ scale: 1.025 }}
                >
                  <motion.div
                    className="image-wrapper"
                    variants={variant}
                    initial="hidden"
                    whileInView="visible"
                  >
                    <div className="text-header-wrapper">
                      <p className="tag-text" style={{ color: "white" }}>
                        Case Study
                      </p>
                    </div>
                    <img className="image" alt={item.title} src={item.imgUrl} />
                  </motion.div>
                </motion.a>
              ))}
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default PortfolioList;
