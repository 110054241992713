import "../../Styling/NavBar.css";
import { IconMenu } from "@tabler/icons-react";
import { useState } from "react";
import { motion } from "framer-motion";

const NavBar = () => {
  const [isNavBarExpanded, setIsNavBarExpanded] = useState(false);
  const toggleNavMenu = () => {
    setIsNavBarExpanded(!isNavBarExpanded);
  };

  const navMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.5,
        delay: 0.2
      },
      display: "block",
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.05,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <div className="navbar-wrapper">
      {/* Desktop Navbar */}
      <nav className="navbar">
        <div className="navbar-title-wrapper">
          <a href="/">
            <img
              className="navbar-title-item"
              alt=""
              src="https://supabase.com/images/customers/logos/light/mobbin.png"
            ></img>
          </a>
        </div>
        {/* <span>
          <a href="/portfolio" className="navbar-item">
            Portfolio
          </a>
        </span> */}
        <span>
          <a href="/contact" className="navbar-item">
            Contact
          </a>
        </span>
      </nav>
      {/* Mobile Navbar */}
      <motion.nav
        className="navbar-mobile"
        style={{ height: "60px" }}
        animate={{
          height: isNavBarExpanded ? '160px' : "60px"
        }}
      >
        <div className="navbar-mobile-wrapper">
          <a href="/">
            <img
              className="navbar-mobile-image"
              alt=""
              src="https://minimal.gallery/wp-content/uploads/2022/12/mobbin-2-630x630.png"
            ></img>
          </a>
          <button
            className="navbar-mobile-button"
            onClick={toggleNavMenu}
            onHoverStart={toggleNavMenu}
            onHoverEnd={toggleNavMenu}
          >
            <IconMenu color="white" />
          </button>
        </div>
        <motion.div
          className="navbar-mobile-content"
          initial="exit"
          animate={isNavBarExpanded ? "enter" : "exit"}
          variants={navMenuAnimate}
        >
          <ul className="navbar-mobile-list">
            <li className="navbar-mobile-list-item">
              <a href="/portfolio" className="navbar-mobile-list-item-text">
                Portfolio
              </a>
            </li>
            <li className="navbar-mobile-list-item">
              <a href="/contact" className="navbar-mobile-list-item-text">
                Contact
              </a>
            </li>
          </ul>
        </motion.div>
      </motion.nav>
    </div>
  );
};

export default NavBar;
