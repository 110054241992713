// LMCT+
import LMCT_Logo from "../Assets/Images/portfolio/lmct/LMCT_Logo.png";
import LMCT_Discounts from "../Assets/Images/portfolio/lmct/LMCT_Discounts.png";
import LMCT_Barcode from "../Assets/Images/portfolio/lmct/LMCT_Barcode.png";
import LMCT_Giveaways from "../Assets/Images/portfolio/lmct/LMCT_Giveaways.png";

// Spotify
import Spotify_Logo from "../Assets/Images/portfolio/spotify/Spotify_Logo.png";
import Spotify_Home from "../Assets/Images/portfolio/spotify/Spotify_Home.png";
import Spotify_Artist from "../Assets/Images/portfolio/spotify/Spotify_Artist.png";
import Spotify_MusicPlayer from "../Assets/Images/portfolio/spotify/Spotify_Music-Player.png";

// Airline
import Airline1 from "../Assets/Images/portfolio/airline/Flight1.png";
import Airline2 from "../Assets/Images/portfolio/airline/Flight2.png";

// Event
import EventDetails from "../Assets/Images/portfolio/events/EventDetails.png";
import EventHome from "../Assets/Images/portfolio/events/EventHome.png";

export const showcaseData = {
  1: {
    imgUrl: LMCT_Discounts,
    title: "Text 2",
  },

  2: {
    imgUrl: LMCT_Giveaways,
    title: "Text 3",
  },

  3: {
    imgUrl: LMCT_Barcode,
    title: "Text 4",
  },

  4: {
    imgUrl: Spotify_Home,
    title: "Text 5",
  },

  5: {
    imgUrl: Spotify_Artist,
    title: "Text 6",
  },

  6: {
    imgUrl: Spotify_MusicPlayer,
    title: "Text 7",
  },
  7: {
    imgUrl: EventHome,
    title: "Text 1",
  },

  8: {
    imgUrl: EventDetails,
    title: "Text 2",
  },
  9: {
    imgUrl: Airline1,
    title: "Text 3",
  },

  10: {
    imgUrl: Airline2,
    title: "Text 4",
  },

  11: {
    imgUrl:
      "https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsubscription-paywall.e3ad5de9.png&w=1920&q=75",
    title: "Text 5",
  },

  12: {
    imgUrl:
      "https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsubscription-paywall.e3ad5de9.png&w=1920&q=75",
    title: "Text 6",
  },

  13: {
    imgUrl:
      "https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fsubscription-paywall.e3ad5de9.png&w=1920&q=75",
    title: "Text 7",
  },
};

export const trustedData = {
  0: {
    imgUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2560px-Google_2015_logo.svg.png",
    title: "Google",
  },

  1: {
    imgUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Airbnb_Logo_B%C3%A9lo.svg/2560px-Airbnb_Logo_B%C3%A9lo.svg.png",
    title: "Air Bnb",
  },
  3: {
    imgUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Spotify_logo_with_text.svg/1200px-Spotify_logo_with_text.svg.png",
    title: "Spotify",
  },
};

export const portfolioData = [
  {
    id: "lmct-case-study",
    title: "LMCT+",
    logo: LMCT_Logo,
    platform: "iOS, Android",
    categories: "Lifestyle",
    imgUrl: LMCT_Giveaways,
    images: [LMCT_Giveaways, LMCT_Discounts, LMCT_Barcode],
  },
  {
    id: "spotify-case-study",
    title: "Spotify",
    logo: Spotify_Logo,
    platform: "iOS, Android",
    categories: "Music",
    imgUrl: Spotify_Home,
    images: [Spotify_Home, Spotify_Artist, Spotify_MusicPlayer],
  },
  {
    id: "spotify-case-study",
    title: "Spotify",
    logo: LMCT_Logo,
    platform: "iOS, Android",
    categories: "Music",
    imgUrl: LMCT_Giveaways,
    images: [LMCT_Giveaways, LMCT_Discounts, LMCT_Barcode],
  },
  {
    id: "spotify-case-study",
    title: "Spotify",
    logo: LMCT_Logo,
    platform: "iOS, Android",
    categories: "Music",
    imgUrl: LMCT_Giveaways,
    images: [LMCT_Giveaways, LMCT_Discounts, LMCT_Barcode],
  },
];
