import "../../Styling/Showcase.css";
import { Box, Typography } from "@mui/material";
import { showcaseData } from "../../Data/ShowcaseData";
import ShowCaseItem from "../../Components/ShowcaseItem";
import useMeasure from "react-use-measure";
import { animate, useMotionValue, motion } from "framer-motion";
import { useEffect, useState } from "react";

const Showcase = () => {
  const FAST_DURATION = 45;

  let [ref, { width }] = useMeasure();

  const xTranslation = useMotionValue(0);

  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  const extendedData = [
    ...Object.values(showcaseData),
    ...Object.values(showcaseData),
  ];

  useEffect(() => {
    let controls;
    let finalPosition = -width / 2 - 8;
  
    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        ease: "linear",
        duration: FAST_DURATION * (1 - xTranslation.get() / finalPosition),
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        ease: "linear",
        duration: FAST_DURATION,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
      });
    }
  
    return controls?.stop;
  }, [mustFinish, rerender, xTranslation, width]);

  return (
    <div className="showcase-wrapper">
      {/* Showcase Header */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4" sx={{ color: "white", fontWeight: 600 }}>
          Showcase
        </Typography>
      </div>

      <div sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" sx={{ mx: 4, mt: 1.5, mb: 4, textAlign: 'center', color: "#c1c1c1", fontWeight: 200, fontSize: 16 }}>
          All the apps & websites featured in the showcase were created by Motion<br/>
          Here are some more amazing interactions made by developers
          with its flexible APIs.
        </Typography>
      </div>
      <main className="py-8" style={{ overflow: "hidden" }}>
        <motion.div
          className="absolute left-0 flex gap-4"
          style={{
            x: xTranslation,
            position: "relative",
            left: 0,
            display: "flex",
            gap: "1rem",
            width: "max-content",
          }}
          ref={ref}
        >
          {extendedData.map((item, idx) => (
            <Box
              key={idx}
              sx={{
                color: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                marginTop: "24px"
              }}
            >
              <ShowCaseItem imgUrl={item.imgUrl} />
            </Box>
          ))}
        </motion.div>
      </main>
    </div>
  );
};

export default Showcase;