import "../Styling/Showcase.css";
import React from "react";
import { motion } from "framer-motion"

export default function CarouselItem({ imgUrl }) {
  return (
    <motion.div
      className="showcase-card"
      style={{ borderRadius: 20, overflow: "hidden" }} // Apply borderRadius to the container
    >
      <img 
        src={imgUrl} 
        alt=""
        style={{ objectFit: "cover", width: "100%", height: "100%" }} // Make sure the image fills the container
      />
    </motion.div>
  );
}
