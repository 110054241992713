import "../../Styling/Quality.css";

const Quality = () => {
  return (
    <div className="quality-wrapper">
      <h2 className="quality-title">
        Explore entire user journeys with flows.
      </h2>
      <div className="quality-content-wrapper">
      <figure className="quality-figure">
          <div className="quality-figure-item-wrapper">
            <div className="quality-figure-video-wrapper">
              <video
                autoPlay
                muted
                loop
                disablePictureInPicture
                disableRemotePlayback
                playsInline
                className="quality-figure-video-first"
              >
                <source
                  type="video/mp4"
                  src="https://bytescale.mobbin.com/FW25bBB/video/assets/videos/lp_flow_video_demo_v1.mp4?mute=true&f=mp4-h264&a=%2Fvideo.mp4&w=500&sh=90&q=6"
                ></source>
              </video>
            </div>
          </div>
          <figcaption className="quality-figure-caption-wrapper">
            <p className="quality-figure-caption-title">Videos</p>
            <p className="quality-figure-caption-details">
              Experience flows the way they were meant to be experienced,
              complete with transitions, micro-interactions, and animations.
            </p>
          </figcaption>
        </figure>
        <figure className="quality-figure">
          <div className="quality-figure-item-wrapper">
            <div className="quality-figure-video-wrapper">
              <video
                autoPlay
                muted
                loop
                disablePictureInPicture
                disableRemotePlayback
                playsInline
                className="quality-figure-video"
              >
                <source
                  type="video/mp4"
                  src="https://bytescale.mobbin.com/FW25bBB/video/assets/videos/lp_prototype_demo_v1.mp4?mute=true&f=mp4-h264&a=%2Fvideo.mp4&w=500&sh=90&q=6"
                ></source>
              </video>
            </div>
          </div>
          <figcaption className="quality-figure-caption-wrapper">
            <p className="quality-figure-caption-title">Prototype mode</p>
            <p className="quality-figure-caption-details">
            Experience flows the way they were meant to be experienced, complete with transitions, micro-interactions, and animations.
            </p>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default Quality;
