import { useState } from "react";
import "../../Styling/Contact/Contact.css";
import useDocumentTitle from "../../Utilities/useDocumentTitle"

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const isDisabled =
    firstName.length === 0 || lastName.length === 0 || email.length === 0;

  useDocumentTitle("Title - Contact Us");

  return (
    <main className="contact-wrapper">
      <main className="container">
        <div className="flex">
          <section className="form-section">
            <div className="form-wrapper">
              <img
                className="image-logo"
                src="https://minimal.gallery/wp-content/uploads/2022/12/mobbin-2-630x630.png"
                alt=""
              />
              <div>
                <h2 className="contact-title">Contact Us</h2>
                <p className="contact-subtitle">
                  Let's create some magic together
                </p>
              </div>
              <div className="form-container">
                <form className="form">
                  {/* Name */}
                  <div className="form-name-wrapper">
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="First name"
                      className="form-name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                      type="text"
                      id="lname"
                      name="lname"
                      placeholder="Last name"
                      className="form-name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  {/* Email or Phone Number */}
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email address or Phone number"
                    className="form-email-address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* Button */}
                  <button className="contact-button" disabled={isDisabled}>
                    <span className="text-wrapper">
                      <span class="truncate">Submit</span>
                    </span>
                  </button>
                  {/* Terms */}
                  <h5 className="disclaimer-text">
                    By continuing, you agree to Mobbin’s{" "}
                    <a className="link" href="/terms">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a className="link" href="/privacy">
                      Privacy Policy
                    </a>
                    .
                  </h5>
                </form>
              </div>
            </div>
          </section>
          {/* Image Section */}
          <section className="image-section">
            <div className="image-section-wrapper">
              {/* First */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Second */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Third */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Forth */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Fifth */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Sixth */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Seventh */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Eighth */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {/* Nineth */}
              <div className="image-slider-container">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="image-wrapper">
                    <img
                      className="image"
                      src="https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fscreen_1_1.e01f1d8a.png&w=1920&q=75"
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </main>
    </main>
  );
};

export default Contact;
