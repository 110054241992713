import "../../Styling/Workflow.css";

const Workflow = () => {
  return (
    <div className="workflow-wrapper">
      <h2 className="workflow-title">From inspiration to creation.</h2>
      <div className="workflow-grid">
        {/* Workflow 1 */}
        <figure className="workflow-figure">
          <div className="workflow-figure-video-wrapper">
            <video
              className="workflow-figure-video"
              autoPlay
              loop
              muted
              disablepictureinpicture
              disableremoteplayback
              playsinline
              poster="https://bytescale.mobbin.com/FW25bBB/image/assets/videos/lp_features_copy_to_figma.mp4?t=0"
            >
              <source
                type="video/mp4"
                src="https://bytescale.mobbin.com/FW25bBB/video/assets/videos/lp_features_copy_to_figma.mp4?mute=true&amp;f=mp4-h264&amp;a=%2Fvideo.mp4&amp;w=800&amp;sh=90&amp;q=7&amp;fps=60"
              />
            </video>
          </div>
          <figcaption>
            <p className="workflow-figure-title">Copy to Figma</p>
            <p className="workflow-figure-body">
              Download designs you like or copy it straight into Figma with our new{" "}
              <a
                href="https://www.figma.com/community/plugin/1332649462188834894/mobbin"
                className="workflow-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Figma plugin
              </a>.
            </p>
          </figcaption>
        </figure>

        {/* Workflow 2 */}
        <figure className="workflow-figure">
          <div className="workflow-figure-video-wrapper">
            <video
              className="workflow-figure-video"
              autoPlay
              loop
              muted
              disablepictureinpicture
              disableremoteplayback
              playsinline
              poster="https://bytescale.mobbin.com/FW25bBB/image/assets/videos/lp_features_save_to_collections.mp4?t=0"
            >
              <source
                type="video/mp4"
                src="https://bytescale.mobbin.com/FW25bBB/video/assets/videos/lp_features_save_to_collections.mp4?mute=true&amp;f=mp4-h264&amp;a=%2Fvideo.mp4&amp;w=800&amp;sh=90&amp;q=7&amp;fps=60"
              />
            </video>
          </div>
          <figcaption>
            <p className="workflow-figure-title">Save to collections</p>
            <p className="workflow-figure-body">
              Collect your favorite designs and upload your own screenshots into one place.
            </p>
          </figcaption>
        </figure>

        {/* Workflow 3 */}
        <figure className="workflow-figure">
          <div className="workflow-figure-video-wrapper">
            <video
              className="workflow-figure-video"
              autoPlay
              loop
              muted
              disablepictureinpicture
              disableremoteplayback
              playsinline
              poster="https://bytescale.mobbin.com/FW25bBB/image/assets/videos/lp_features_comments.mp4?t=0"
            >
              <source
                type="video/mp4"
                src="https://bytescale.mobbin.com/FW25bBB/video/assets/videos/lp_features_comments.mp4?mute=true&amp;f=mp4-h264&amp;a=%2Fvideo.mp4&amp;w=800&amp;sh=90&amp;q=7&amp;fps=60"
              />
            </video>
          </div>
          <figcaption>
            <p className="workflow-figure-title">Leave comments</p>
            <p className="workflow-figure-body">
              Take notes upon saving so you’ll never forget the context in the future.
            </p>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default Workflow;
