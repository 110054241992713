import "../../Styling/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer-content">
            <div className="footer-logo-wrapper">
                <img className="footer-logo-image" alt="" src="https://mobbin.com/safari-pinned-tab.svg?v=2.1"></img>
                <p className="footer-logo-text">Design better digital experiences with Mobbin.</p>
            </div>
            <div className="footer-links-wrapper">
                <a href="/" className="footer-links-link">Pricing</a>
                <a href="/" className="footer-links-link">Pricing</a>
                <a href="/" className="footer-links-link">Pricing</a>
                <a href="/" className="footer-links-link">Pricing</a>
                <a href="/" className="footer-links-link">Pricing</a>
                <a href="/" className="footer-links-link">Pricing</a>

                <a href="/" className="footer-links-link">Instagram</a>
                <a href="/" className="footer-links-link">Instagram</a>
                <a href="/" className="footer-links-link">Instagram</a>
            </div>
            {/* Mobile Footer */}
            <div className="footer-mobile-wrapper">
                <p>© Mobbin 2018–2024</p>
                <div className="footer-mobile-links-wrapper">
                    <a href="/" className="footer-mobile-links-link">Privacy Policy</a>
                    <a href="/" className="footer-mobile-links-link">Terms</a>
                </div>
            </div>
            {/* Footer */}
            <p className="footer-title">© Mobbin 2018–2024. All rights reserved</p>
            <div className="footer-terms-wrapper">
                <a href="/" className="footer-terms-link">Privacy policy</a>
                <a href="/" className="footer-terms-link">Terms</a>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
